<template>
  <div class="app">
    <div class="search_area">
      <icon
        name="search"
        class="icon_search"
        :style="{ marginLeft: I_marginLeft_p_icon }"
      />
      <input
        type="text"
        class="search"
        v-model="I_value_p"
        @click="haveInput_phone"
        @blur="haveOutput_phone"
        :style="{
          border: I_border_p,
          width: I_width_p,
          marginLeft: I_marginLeft_p,
        }"
      />
    </div>
    <div class="result_area" v-show="result_isShow_p">
      <ul>
        <li v-for="(item, index) in results" :key="index" @click="clear">
          {{ item.essay_topic + ">>" }}
          <router-link
            :to="{
              name: 'fl',
              params: {
                cname: item.essay_topic,
                cid: item.essay_fatherId,
                eid: item.essay_id,
              },
            }"
            class="rlColor"
          >
            <i v-html="result[index]"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import icon from "vant/lib/icon";
import "vant/lib/icon/style";
import api from "@/api";
import qs from "qs";
// import { emit } from "vant/lib/utils/functional";
export default {
  components: {
    icon,
  },
  methods: {
    haveInput_phone() {
      this.I_border_p = "1px solid green";
      this.I_width_p = "70%";
      this.I_marginLeft_p = "5%";
      this.I_marginLeft_p_icon = "6%";
    },
    haveOutput_phone() {
      if (!this.I_value_p) {
        this.I_border_p = "1px solid rgb(100, 100, 100)";
        this.I_width_p = "40%";
        this.I_marginLeft_p = "20%";
        this.I_marginLeft_p_icon = "22%";
      }
    },
    clear() {
      this.I_value_p = "";
      this.$emit("changePopupShow", false);
    },
  },
  data() {
    return {
      I_border_p: "1px solid rgb(100, 100, 100)",
      I_width_p: "40%",
      I_marginLeft_p: "20%",
      I_value_p: "",
      result_isShow_p: false,
      I_marginLeft_p_icon: "22%",
      results: [],
      result: [],
    };
  },
  watch: {
    I_value_p(newValue) {
      if (newValue != "") {
        this.result_isShow_p = true;
        setTimeout(() => {
          // 从vuex中匹配数据
          // console.log(newValue);
          const requestData = {
            key: newValue,
          };
          const formData = qs.stringify(requestData);
          api
            .post("/essay/matching", formData, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then((res) => {
              // console.log(res.data);
              this.result.length = 0;
              this.results = res.data;
              for (var i = 0; i < this.results.length; i++) {
                var temp = "";
                for (
                  var j = 0;
                  j < this.results[i].essay_title.split(newValue).length;
                  j += 2
                ) {
                  if (
                    j + 1 <
                    this.results[i].essay_title.split(newValue).length
                  ) {
                    // console.log(this.results[i].essay_title.split(newValue)[j]);
                    temp += `${
                      this.results[i].essay_title.split(newValue)[j]
                    }<strong class="keyword">${newValue}</strong>${
                      this.results[i].essay_title.split(newValue)[j + 1]
                    }`;
                  } else {
                    // console.log(this.results[i].essay_title);
                    temp += `${
                      this.results[i].essay_title.split(newValue)[j]
                    }<strong class="keyword">${newValue}</strong>
                  `;
                  }
                }
                this.result.push(temp);
                // console.log(temp);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }, 500);
      } else {
        this.result_isShow_p = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
a {
  color: black;
}
.lac {
  color: green;
  font-weight: bold;
}
.app {
  .search_area {
    width: 100%;
    height: 100%;
    .search {
      outline: none;
      height: 35px;
      margin-top: 15px;
      border-radius: 25px;
      font-size: 16px;
      padding: 0 23px;
      transition: all 0.3s;
    }
    .icon_search {
      position: absolute;
      margin-top: 25.5px;
      font-size: 18px;
      transition: all 0.3s;
    }
  }

  .result_area {
    width: 76%;
    max-height: 350px;
    margin-left: 10%;
    overflow-y: auto;
    // background-color: green;
    margin-top: 2px;
    position: fixed;
    z-index: 9999;
    background-color: white;
    border: 1px solid rgb(100, 100, 100);
    ul {
      list-style: none;
      background-color: white;
      li {
        font-size: 14px;
        margin: 10px 5px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        i {
          font-style: normal;
          color: black;
          &:hover {
            color: green;
          }
        }
        .keyword {
          font-style: normal;
        }
      }
    }
  }
}
</style>
