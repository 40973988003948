<template>
  <div class="z6000001">
    <div class="essayBody">
      <p class="bigTitle">欢迎来到jaebook</p>
      当您踏入我的博客世界，仿佛踏入一座知识的花园，四季轮转，各具特色。这个世界被细致地分割成四大板块，每一块都是我心灵的一角，储藏着生活的点滴和学识的珍宝。
      <br />
      <br />
      首先，<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '学习之路', cid: 101, eid: 1010001 },
          }"
          class="rlColor"
          >生活杂谈</router-link
        ></i
      >板块犹如一汪清泉，涵盖<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '学习之路', cid: 101, eid: 1010001 },
          }"
          class="rlColor"
          >学习之路</router-link
        ></i
      >、<i class="c1">
        <router-link
          :to="{
            name: 'fl',
            params: { cname: '生活日记', cid: 102, eid: 1020001 },
          }"
          class="rlColor"
          >生活日记</router-link
        ></i
      >、<i class="c1">
        <router-link
          :to="{
            name: 'fl',
            params: { cname: '每日随笔', cid: 104, eid: 1040001 },
          }"
          class="rlColor"
          >每日随笔</router-link
        ></i
      >和<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '产品评测', cid: 103, eid: 1030001 },
          }"
          class="rlColor"
          >产品测评</router-link
        ></i
      >等内容。在这里，我将探讨学习的曲径通幽，记录生活的细枝末节，留存心灵的碎片，以及分享对产品的真挚体验。
      <br />
      <br />
      其次，<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '编程笔记', cid: 200, eid: 2000001 },
          }"
          class="rlColor"
          >编程笔记</router-link
        ></i
      >板块如同一本编织技艺的手札，记录着我在编程之路上的点滴心得。无论是代码的纷繁织网还是技术的花火绽放，我将在这里与您分享编程的乐趣与智慧，探索数字世界的奥秘。
      <br />
      <br />
      另外，<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '知识碎片', cid: 301, eid: 3010001 },
          }"
          class="rlColor"
          >内容碎片</router-link
        ></i
      >板块是一座知识的宝库，收纳了各种闪光的智慧之星。在这里，我将<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '知识碎片', cid: 301, eid: 3010001 },
          }"
          class="rlColor"
          >分享碎片化的知识点</router-link
        ></i
      >和<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '资源分享', cid: 302, eid: 3020001 },
          }"
          class="rlColor"
          >珍贵资源</router-link
        ></i
      >，为您提供一扇通往更广阔世界的窗户，让思想的火花在这里迸发。
      <br />
      <br />
      最后，<i class="c1"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '项目实战', cid: 400, eid: 4000001 },
          }"
          class="rlColor"
          >项目实战</router-link
        ></i
      >板块是我的刀尖舞台，展示着实践的风采和智慧的结晶。我将分享自己在项目实战中的心路历程和经验总结，探讨创意的火花如何在实践中迸发，期待与您共同探讨创新的可能性。
      <br />
      <br />
      在这片博客的海洋里，思想的船只穿行于知识的浪潮之间，随着每一篇文章的探索，我们将一同感受生活的温暖，品味学识的甘甜。欢迎您常来光顾，与我一同漫步于这个心灵的乐园，分享彼此的感悟与启迪。愿我们在这个虚拟的世界里，共同绽放着智慧的花朵，永不止息。
      <br /><br />
      <p class="bigTitle">这是一本巨大的学习笔记</p>
      <p class="timeMsg">文章最后一次修改时间：{{ time }}</p>
    </div>
    <beianMsgVue class="beian"></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";

export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
.z6000001 {
  // background-color: red;
  height: auto;
  position: absolute;
  .bigTitle {
    color: green;
    font-size: 2rem;
    text-align: center;
    margin: 20px 0;
  }
  .essayBody {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2rem;
  }
  .beian {
    margin-top: 3rem;
  }
  .timeMsg {
    // float: right;
    margin-top: 2rem;
    font-size: 14px;
    color: rgb(201, 201, 201) k;
  }
  .rlColor,
  .c1 {
    color: green;
    font-style: normal;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z6000001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z6000001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
