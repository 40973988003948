<template>
  <div id="app">
    <div class="topNav">
      <topNavAreaVue> </topNavAreaVue>
    </div>
    <div class="mainBody">
      <contetnAreaVue></contetnAreaVue>
    </div>
  </div>
</template>

<script>
import topNavAreaVue from "./components/topNavArea.vue";
import contetnAreaVue from "./components/contentArea.vue";

import api from "./api.js";

export default {
  components: {
    contetnAreaVue,
    topNavAreaVue,
  },

  methods: {
    fetchEssayData() {
      api
        .get("/essay/totitle")
        .then((res) => {
          // console.log(res);

          for (var i = 0; i < res.data.length; i++) {
            this.$store.commit("changeEssay", res.data[i]);
          }
          // console.log(this.$store.state.notesEssay);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.$nextTick(() => {
      this.fetchEssayData();
    });
  },
};
</script>

<style lang="less">
@font-face {
  font-family: youzai-regular;
  src: url("@/assets/font/yozai/ttf/Yozai-Regular.ttf");
}
@font-face {
  font-family: youzai-medium;
  src: url("@/assets/font/yozai/ttf/Yozai-Medium.ttf");
}
@font-face {
  font-family: youzai-light;
  src: url("@/assets/font/yozai/ttf/Yozai-Light.ttf");
}
@font-face {
  font-family: youzai-bold;
  src: url("@/assets/font/yozai/ttf/Yozai-Bold.ttf");
}
@font-face {
  font-family: alimama;
  src: url(@/assets/font/alimama/AlimamaFangYuanTiVF-Thin.ttf);
}
* {
  font-family: alimama;
  // font-weight: bold;
}
i {
  font-style: normal;
}
.cen {
  text-align: center;
}
.c1 {
  color: green;
}
.big {
  font-weight: bold;
}
.c2 {
  color: rgb(16, 63, 91);
}
.c3 {
  color: #f40;
}
.c4 {
  color: rgb(160, 219, 23);
}
.c5 {
  color: #222e7dba;
}
.mg20 {
  margin: 2rem 0;
}
.title {
  // color: green;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.ftitle {
  // color: green;
  font-size: 14px;
  font-weight: bold;
  color: #5e5e5eba;
  text-align: center;
}
.timeMsg {
  // float: right;
  margin-top: 2rem;
  font-size: 1rem;
  color: rgb(142, 140, 140);
}
.po {
  cursor: pointer;
}
.tip {
  color: rgba(94, 94, 94, 0.664);
  font-size: 14px;
}
</style>
