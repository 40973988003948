<template>
  <div class="z4000002">
    <div class="essayBody">
      <p class="title c1">一个自动化LOGO设计网站</p>
      <br />
      <br />
      <p class="cen">先鸽着😁</p>
      <br />
      <br />
      <!-- <p class="timeMsg">文章最后一次修改时间：{{ time }}</p> -->
    </div>
    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
.z4000002 {
  // background-color: blue;
  height: auto;
  position: absolute;
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z4000002 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z4000002 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
