<template>
  <div class="firstLevelList">
    <p class="catalogueName">{{ catalogueName }}</p>
    <ul class="catalogue">
      <li class="essayName" v-for="(item, index) in essay" :key="index">
        <router-link
          :to="{
            name: 'fl',
            params: {
              cname: catalogueName,
              cid: id,
              eid: item.essay_id,
            },
          }"
          class="rlColor"
        >
          {{ item.essay_title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Bus from "../utils/EventBus.js";
export default {
  data() {
    return {
      // 文章列表
      essay: [],
      // 目录名
      catalogueName: "",
      id: 0,
      eid: 0,
    };
  },
  methods: {
    // 获取目录名
    // 默认是欢迎页面
    toEssay(cid) {
      // this.essay.length = 0;
      cid = Number(cid);
      switch (cid) {
        case 101:
          return this.$store.state.learningEssay;
        case 102:
          return this.$store.state.diaryEssay;

        case 103:
          return this.$store.state.evaEssay;

        case 104:
          return this.$store.state.moodEssay;

        case 200:
          return this.$store.state.notesEssay;

        case 301:
          return this.$store.state.konwledgeEssay;

        case 302:
          return this.$store.state.resourceEssay;

        case 400:
          return this.$store.state.projectEssay;

        case 500:
          return this.$store.state.authorEssay;

        case 600:
          return this.$store.state.homePageEssay;
      }
    },
  },
  watch: {
    "$route.params": {
      immediate: true, // 立即执行一次
      handler(newParams) {
        // 根据新传入的参数进行相应操作
        this.catalogueName = newParams.cname;
        this.id = newParams.cid;
        this.eid = newParams.eid;
        this.essay = [];

        // Bus.$emit("onShowEssay", newParams.eid);
        // console.log(newParams.eid);
        var temp = {};
        setTimeout(() => {
          for (var i = 0; i < this.toEssay(this.id).length; i++) {
            this.essay.push(this.toEssay(this.id)[i]);
            temp = this.toEssay(this.id)[i];
            if (temp.essay_id == newParams.eid) {
              // console.log(temp.essay_sonLevelList);
              Bus.$emit("essay", temp);
              document.title = "jaebook-" + temp.essay_title;

              //在这里获取到当前文章目录和文章详细内容，可以分别传给二级目录和内容展示区域
            }
          }
        }, 500);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.lac {
  color: green;
  font-weight: bold;
}
.leac {
  color: green;
  font-weight: bold;
}
a {
  color: black;
}
.firstLevelList {
  // position: absolute;
  width: 260px;
  height: 85%;
  margin-left: 30px;
  margin-top: 20px;
  position: fixed;
  border-right: 1px solid #cfd4db;

  .catalogueName {
    font-size: 24px;
  }
  .catalogue {
    height: 650px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      font-size: 16px;
      padding-left: 10px;
      word-wrap: break-word;
      margin: 20px 25px;
      cursor: pointer;
      // color: black;
      &:hover {
        color: green;
      }
    }
  }
}
</style>
