<template>
  <div id="app">
    <popup v-model="phoneNavIsShow" position="left" class="phoneNav">
      <div class="phoneNavSearchArea">
        <topNavPhoneSearchVue
          @changePopupShow="changePopupShow"
        ></topNavPhoneSearchVue>
      </div>
      <div class="phontNavlistArea">
        <topNavPhoneLevelListVue
          @changePopupShow="changePopupShow"
        ></topNavPhoneLevelListVue>
      </div>
    </popup>
    <div class="change_button_phone">
      <icon name="list-switching" class="change_button" @click="phoneNavShow" />
    </div>
    <logoTextVue class="logo"></logoTextVue>
    <topNavSearchVue class="search"></topNavSearchVue>
    <topNavTopicListVue class="topLevelList"></topNavTopicListVue>
  </div>
</template>

<script>
import icon from "vant/lib/icon";
import "vant/lib/icon/style";
import popup from "vant/lib/popup";
import "vant/lib/popup/style";
import logoTextVue from "./logoText.vue";
import topNavSearchVue from "./topNavSearch.vue";
import topNavPhoneSearchVue from "./topNavPhoneSearch.vue";
import topNavPhoneLevelListVue from "./topNavPhoneLevelList.vue";
import topNavTopicListVue from "./topNavTopicList.vue";
export default {
  components: {
    logoTextVue,
    topNavSearchVue,
    icon,
    popup,
    topNavPhoneSearchVue,
    topNavPhoneLevelListVue,
    topNavTopicListVue,
  },
  data() {
    return {
      phoneNavIsShow: false,
    };
  },
  methods: {
    phoneNavShow() {
      this.phoneNavIsShow = true;
    },
    changePopupShow(Value) {
      this.phoneNavIsShow = Value;
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "youzai";
  src: url("../assets/font/yozai/ttf/Yozai-Regular.ttf");
}

#app {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  // border-bottom: 1px solid rgb(215, 212, 212);
  .change_button {
    color: green;
    margin-left: 10px;
  }
  .search {
    left: 475px;
    top: 5px;
  }
  .phoneNav {
    .phoneNavSearchArea {
      width: 100%;
      height: 10%;
    }
    .phontNavlistArea {
      width: 100%;
      height: 90%;
      overflow: auto;
    }
  }
  .topLevelList {
    top: 10px;
    right: 35px;
  }
}

/* 对于屏幕宽度小于 600px 的设备应用以下样式 */
@media only screen and (max-width: 600px) {
  #app {
    height: 8%;
    .logo {
      top: 5px;
      font-size: 32px;
      left: calc(50% - 100px);
    }
    .search {
      display: none;
    }
    .change_button {
      width: 30px;
      height: 30px;
      font-size: 30px;
      display: block;
      margin-top: 15px;
    }
    .phoneNav {
      width: 70%;
      height: 100%;
    }
    .topLevelList {
      display: none;
    }
  }
}

/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  #app {
    height: 8%;
    .logo {
      top: 5px;
      font-size: 32px;
      left: calc(50% - 100px);
    }
    .search {
      display: none;
    }
    .change_button {
      width: 38px;
      height: 38px;
      font-size: 38px;
      display: block;
      margin-top: 11px;
    }
    .phoneNav {
      width: 60%;
      height: 100%;
    }
    .topLevelList {
      display: none;
    }
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) and (max-width: 1489px) {
  #app {
    height: 10%;
    .logo {
      top: 10px;
      font-size: 38px;
      left: 100px;
    }
    .search {
      display: block;
    }
    .change_button {
      display: none;
    }
    .topLevelList {
      display: none;
    }
  }
}

@media only screen and (min-width: 1490px) {
  #app {
    height: 10%;
    .logo {
      top: 10px;
      font-size: 38px;
      left: 100px;
    }
    .search {
      display: block;
    }
    .change_button {
      display: none;
    }
    .topLevelList {
      display: block;
    }
  }
}
</style>
