<template>
  <div class="z1040001">
    <!-- <audio ref="audioPlayer" src="./music/余佳运 - 九月底.ogg" autoplay></audio> -->
    <div class="essayBody">
      <div class="shi">
        <p class="title">《三五七言》</p>
        <p class="ftitle">李白</p>
        <br />
        <p class="cen">秋风清，秋月明，落叶聚还散，寒鸦栖复惊</p>
        <p class="cen">
          <i class="c1 big">相思相见何时了？</i>此时此夜难为情！
        </p>
        <p class="cen">
          入我相思门，知我相思苦，长相思兮长相忆，短相思兮无穷极
        </p>
        <p class="cen">早知如此绊人心，何如当初莫相识</p>
        <br />
      </div>
      <img src="./image/1040001.jpg" alt="" class="back" />

      <br />
      <br />
      <p class="cen">那天晚上的风好舒服的咧~😊</p>
      <br />
      <br />
      <p class="timeMsg">文章最后一次修改时间：{{ time }}</p>
      <br />
      <br />
    </div>
    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  // mounted() {
  //   this.$refs.audioPlayer.play();
  // },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
.shi > p,
.shi i {
  font-family: youzai-medium;
  line-height: 2.3rem;
}
.back {
  width: 60%;
  height: 10%;
  margin-left: 20%;
}
// .timeMsg {
//   float: right;
// }
.z1040001 {
  // background-color: blue;
  height: auto;
  position: absolute;
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z1040001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z1040001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
