var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"search_area"},[_c('icon',{staticClass:"icon_search",style:({ marginLeft: _vm.I_marginLeft_p_icon }),attrs:{"name":"search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.I_value_p),expression:"I_value_p"}],staticClass:"search",style:({
        border: _vm.I_border_p,
        width: _vm.I_width_p,
        marginLeft: _vm.I_marginLeft_p,
      }),attrs:{"type":"text"},domProps:{"value":(_vm.I_value_p)},on:{"click":_vm.haveInput_phone,"blur":_vm.haveOutput_phone,"input":function($event){if($event.target.composing){ return; }_vm.I_value_p=$event.target.value}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.result_isShow_p),expression:"result_isShow_p"}],staticClass:"result_area"},[_c('ul',_vm._l((_vm.results),function(item,index){return _c('li',{key:index,on:{"click":_vm.clear}},[_vm._v(" "+_vm._s(item.essay_topic + ">>")+" "),_c('router-link',{staticClass:"rlColor",attrs:{"to":{
            name: 'fl',
            params: {
              cname: item.essay_topic,
              cid: item.essay_fatherId,
              eid: item.essay_id,
            },
          }}},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.result[index])}})])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }