<template>
  <div class="z5000001">
    <div class="essayBody">
      <p class="c1">一个热爱编程与设计的学生</p>
      <br />
      <p class="c2">⭐关于作者</p>

      当我站在计算机的世界里，你可以称呼我为Jae。作为一名大一的计算机学生，我的热爱源自对编程的执着追求。我专注于
      Node 全栈开发，尤其钟情于前端和 Web
      开发领域，同时也喜欢挑战微信小程序的开发。除了技术领域，我还热衷于探索新技术，追求专业软件设计与开发。我的多面技能让我在创作中散发出独特的魅力。
      <br />
      <br />
      <p class="c2">⭐个人作品</p>
      目前，我的个人作品尚未公开展示，但我正在努力学习与实践中。我相信通过不懈的努力，我将能够在编程与设计领域展现出自己的才华与独特见解。
      <br />
      <br />
      <p class="c2">⭐社交媒体</p>
      在社交媒体方面，我善于利用各种平台展示自己的技术与设计理念。您可以在以下平台找到我的身影：
      <br />
      <div class="mediaShow">
        <ul>
          <li>
            <a
              href="https://blog.csdn.net/m0_46223203?type=blog"
              target="_blank"
              >CSDN ：https://blog.csdn.net </a
            ><br />
          </li>
          <li>
            <a href="https://github.com/JayWuYu08?tab=projects" target="_blank"
              >Giuhub ：https://github.com/ </a
            ><br />
          </li>
          <li>
            <a href="https://gitee.com/dream-jiege" target="_blank"
              >Gitee ：https://gitee.com/dream-jiege </a
            ><br />
          </li>
          <li>
            <a href="https://juejin.cn/user/2245677729454969" target="_blank"
              >掘金网 ：https://juejin.cn/user</a
            ><br />
          </li>
          <li>
            <a
              href="https://www.zhihu.com/people/60-20-40-38-94"
              target="_blank"
              >知乎 ：https://www.zhihu.com/people/</a
            ><br />
          </li>
          <li>
            <a
              href="https://www.douyin.com/user/MS4wLjABAAAAuKbPxl0qfI_iRyAE7AiTdL4OR2HDj79to2qUAvwJs-yImgd_bq5rfyXa8inAJ6Rv"
              target="_blank"
              >抖音 ：http://985.so/9dxdf</a
            ><br />
          </li>
          <li>
            <a
              href="https://space.bilibili.com/480276330?spm_id_from=333.1007.0.0"
              target="_blank"
              >B站 ：https://space.bilibili.com/480276330</a
            ><br />
          </li>
          <li>
            <a target="_blank">个人微信公众号：jaebook </a>

            <br />

            <img src="./image/wx.png" alt="" class="vxpublic" />
          </li>
        </ul>
        在我的世界里，编程艺术与运动乐趣并存，展现出一个年轻计算机学子的多面魅力。我相信，通过不断学习与实践，我将能够在技术与设计领域走得更远。
        <br />
        <p class="timeMsg">文章最后一次修改时间：{{ time }}</p>
        <br />
      </div>
    </div>
    <beianMsgVue class="beian"></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Arial, Helvetica, sans-serif;
}
.z5000001 {
  // background-color: blue;
  height: auto;
  position: absolute;
  line-height: 2.4rem;
  font-size: 1rem;
  .vxpublic {
    width: 80%;
    margin-left: 10%;
    height: 20%;
  }
  .beian {
    margin-bottom: 3rem;
  }
  .mediaShow {
    width: 100%;
    height: auto;
    // background-color: rgba(131, 131, 131, 0.418);
    overflow: hidden;
    ul {
      // list-style-type: circle;
      margin: 0.5rem 1.5rem;
    }
    a {
      color: green;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .c1 {
    color: green;
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    text-align: center;
  }
  .c2 {
    font-weight: bold;
    font-size: 1.4rem;
    color: rgb(13, 114, 107);
  }
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z5000001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z5000001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
