import { render, staticRenderFns } from "./ejjjjjaEssay.vue?vue&type=template&id=47e1a780&scoped=true"
import script from "./ejjjjjaEssay.vue?vue&type=script&lang=js"
export * from "./ejjjjjaEssay.vue?vue&type=script&lang=js"
import style0 from "./ejjjjjaEssay.vue?vue&type=style&index=0&id=47e1a780&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e1a780",
  null
  
)

export default component.exports