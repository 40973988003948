<template>
  <div class="z1020002">
    <div class="essayBody">
      <p class="title c1">行事低调，不骄不躁</p>
      <br />
      <br />
      <p class="cen big c2">
        最近明白一个道理，及时止损真的是一件非常重要的事情
      </p>
      <br />
      <p>
        最近去参加了老师带的一个项目，虽然是一个项目，是一个系统的开发，但是不按正常前后端分离的开发流程，选用的是一个对于我而言比较陌生的语言和方向，本来我还想着学就学吧，即使我现在对于我自己想走的研究方向还有很多东西没学就去研究一个新的方向和东西，就想着这是一个锻炼的机会并且趁机多学一点东西，但是越学越讨厌，越学越厌恶，然后想着要放弃但是又不想放着这项目不做，因为这个项目只剩下一个名额去争取，<i
          class="c1 big"
          >但是最终把精力逐渐消耗在这不断的挣扎之中直到耗尽，导致了后面几天甚至一个星期都没有心思去学习</i
        >，完全静不下心来去研究，开发和学习。唉，遇到问题还是需要解决的，现在心情平复了一点之后我就复盘了一下导致我内耗的原因，还是要一点点进步吧
      </p>
      <br />
      <img src="./image/a2.jpg" alt="" class="poster" />
      <br />
      <br />
      <p>
        <i class="c1 big">原因一：我太过急于求成:</i>
        <br />本来十几天的课程我只打算用两天直接拿下，然后快速做出来一点东西证明自己，可惜虽然课是看完了【<i
          class="c2 big"
          >也可能跟那个网课的讲课模式不适合我，我真的不想听他在那里啰嗦的说很多东西，导致我非常厌倦听那个网课，一点听不进去，即使那个课是所有人都认为是最好的</i
        >】，但是却没有精力去复习和复盘【
        因为我非常讨厌上学校的课，不管是数学还是物理之类的，我觉得大部分都是在浪费我的时间，所以大部分精力我都浪费在去和不去上课的挣扎上，特别是那些没用要求又严格的课，我都服了】，并且我的学习方式发生改变之后似乎对这门语言的学习的效果并没有那么的好【<i
          class="c2 big"
          >从以前专注听课变成了现在专注于写笔记，有点类似于形式主义了</i
        >】直到现在我才直到这种学习方法的错误。并且我有点小瞧了这门语言的麻烦性【也可能跟我最近身体上太过疲惫导致我大脑发生泵机，脑子都不太够用了】当我尝试着直接去做那个系统的时候，处处碰壁导致了我完全没有了心思去搞这个开发了，并且将我的精力消耗尽，导致了后面几天的心情低落，静不下心来
      </p>
      <br />
      <br />
      <img src="./image/a3.gif" alt="" class="poster" />

      <p>
        <br />
        <i class="c1 big">原因二：我对研究太过于不专注:</i
        ><br />在学习和开发着一个项目的时候心里却又想了无数个项目，导致我无法静下心来去专注于一个项目的开发，时常想着其他项目的发展路线，有时候想法太多也并不是好事，如果能将所有想法都专注于同一个项目，这才是真正的优点，不然活跃的思维力反而会成为一个影响专注力的缺点。这个原因也很大程度的影响了我的精力和心情，导致我三心二意，最终没有一个项目是做出来的，很多想法也只是记了下来但是并没有去实现，一是自己的实力不够【<i
          class="c2 big"
          >队友都不能帮上我的忙，每个人都有自己的忙的地方</i
        >】，二是自己的时间不够【学校的sb课浪费我的时间】，每次开始构思的时候就想着要去做其他事情【<i
          class="c2 big"
          >跟高中时期我的同学一样，写着语文又想着英语，写着英语又想着数学，看来最终教育还是形成了闭环</i
        >】这是一个不好的习惯，必须要改掉并且学会专注
      </p>

      <br />
      <br />
      <img src="./image/a4.gif" alt="" class="poster" />
      <br />
      <p>
        <i class="c1 big">原因三：其实我本身对学校里面这些大创项目不太感兴趣:</i
        ><br />
        首先是我对学校内大部分创新创业的比赛的刻板印象是：“PPT大赛”，许多拿奖的课题都是一些遥不可及的项目【<i
          class="c2 big"
          >也可能确实是我的眼光狭隘问题，可能我真的还没有遇到能让我学到很多东西的队伍或老师</i
        >】。其次我对于竞赛和学习的态度是：学东西在哪里学不是学，我自己学的话可能效率更高，因为那些比我大一两年的师兄并不一定能教会我，老师也并不一定会倾囊相授，更何况这个队伍只剩下最后一个名额，如果不能通过这个名额进入到开发队伍中那跟着就几乎没有任何意义了因为学不到任何东西【起码我是这样认为的】并且这个名额的选拔还是通过一些不怎么正规的方式去选拔，在我听到不是通过技术能力去选择这个最后的位置的时候我立刻想离开了，因为没有意思【没有嘲讽意思，只是个人想法，对事不对人，并没有说认为谁的实力不行】
      </p>
      <br />
      <br />
      <img src="./image/a5.gif" alt="" class="poster" />

      <p>
        <br />
        <i class="c1 big">原因四：最近专注力下降了很多是真的:</i>
        <br />因为最近开始沉迷于网络视频了，无论是b站还是抖音，总想着在视频平台上面刷一点有用的东西，但是最后不知不觉又刷了一些低质视频刷了半天，当然也可能是因为我最近身处的环境不太适合学习和我本身的疲惫问题【训练确实有点累了】其实我早就明白的一个道理是：真正想学习的人是不会轻易被干扰到的，所有的不专注只是我对自己欺骗的结果。
      </p>
      <br />
      <br />
      <img src="./image/a8.jpg" alt="" class="poster" />

      <p class="c5 cen big"><br />原因分析写完了，写一下总结吧：</p>
      <br />
      <p>
        一：以后的学习需要<i class="c2 big">做好详细的学习规划</i
        >，选择适合自己的课程而不要随波逐流，根据学习内容合理的规划时间，不要给自己增加无形的压力。
      </p>
      <br />
      <p>
        二：不要有攀比之心，<i class="c2 big">没必要太过于急于证明自己</i
        >，先让自己慢下来沉淀一下，金子总会发光，不要担心自己被淹没,将心思放在提升自己
      </p>
      <br />
      <p>
        三：学习方式改回来：<i class="c2 big">认真听课而不是认真记笔记</i
        >，可以先将学习的目录大纲记录下来，先把内容听会，最后也要记得复习总结
      </p>
      <br />
      <p>
        四：<i class="c2 big">学习的精力分配一定要做好分配</i
        >，特别是在学习一个新的领域或者一个新方向的知识的时候，不要快速将自己的学习精力浪费掉，阶段性的合理分配好，将学习时间控制好
      </p>
      <br />
      <p>
        五：<i class="c2 big">提高自己的专注力</i
        >，无论在做什么项目或者学习的时候，将所有思维和精力都专注于一个项目和学习的专注之中，不要将自己的优点变成缺点
      </p>
      <br />
      <p>
        六：一个好的团队很重要，如何去融入和管理好一个团队更重要，所以<i
          class="c2 big"
          >平衡好独立和团队开发的重心</i
        >，做好多方面的准备，多提升自己的实力，保证自己独自一人的时候也有能力养活自己，不能只依赖于队友
      </p>
      <br />
      <p>
        七：及时止损，看不到尽头的路就不要走了，特别是自己已经看清楚了的路，要<i
          class="c2 big"
          >相信自己的判断力和理解力</i
        >，不适合的终究是不适合
      </p>
      <br />
      <p>
        八：<i class="c2 big">将娱乐的时间放在特定的时间</i
        >，不要娱乐至死，游乐成瘾，适度放松，不要被网络上的信息流带坏，想要了解更多的知识应该通过正确的学习方式去学习【本来就应该要认识到娱乐平台的算法是让人越来越差的】
      </p>
      <br />
      <p>
        九：无论做任何事情，都要记住内心的口诀：<i class="c1 big"
          >行事低调，不骄不躁，专注做事！</i
        >
      </p>
      <br />
      <p>十：多做总结，多做实用性规划还是挺好的</p>
      <br />
      <img src="./image/a7.jpg" alt="" class="poster" />
      <p class="title big c5">
        其实最重要的一点就是，正确的认识好自己，其实自己还是有很多地方需要去学习，很多知识需要去掌握，但是自己本身却又太过于不屑于太多项目，这一点确实要承认自己的错误，骄傲自负永远是失败的导火索，这一点必须要改掉，希望自己以后可以真正的做到以一颗求知，低调的心去对待所有人和事
      </p>
      <br />
      <p class="timeMsg">文章最后一次修改时间：{{ time }}</p>
      <br />
    </div>

    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Arial, Helvetica, sans-serif;
}
.z1020002 {
  // background-color: blue;
  height: auto;
  position: absolute;
  line-height: 2.2rem;
  font-size: 1.1rem;
  .title {
    font-size: 2rem;
  }
  p {
    font-size: 1.1rem;
  }
  .timeMsg {
    font-size: 1rem;
  }
  .poster {
    width: 40%;
    height: 20%;
    margin-left: 30%;
  }
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z1020002 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z1020002 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
