<template>
  <div class="z1010001">
    <div class="essayBody">
      <p class="title c1">《关系》</p>
      <br />
      <br />
      <p class="cen">
        任何一段关系会怎样的影响你的情绪，关键是看你以怎样的心态，以怎样的地位去看待这一段关系。
      </p>
      <br />
      <br />
      <p class="cen">
        即便你做的再好，总会有人对你不满，因为他讨厌的是你这个人而不是这件事
      </p>
      <br /><br />
      <p class="title">但是总会有人期待着你去找他玩！或许他也在等着你🌙</p>
      <br /><br />
      <img
        src="./image/meme.jpg"
        alt=""
        style="width: 60%; height: 40%; margin-left: 20%; margin-top: 5%"
      />
      <br />
      <br />
      <p class="cen c3 big">
        所以不要去管那些让你不开心的人和事，把好的心情留给那些喜欢你的人😁
      </p>
      <br />
      <p class="cen c2">
        注意：请不要故作高冷，因为那样不会让你的朋友更关心你，反而有可能让你们的关系产生隔阂！
      </p>
      <br />
      <p class="timeMsg">文章最后一次修改时间：{{ time }}</p>
      <br />
      <br />
    </div>

    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
export default {
  name: "helloPage",
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
.z1010001 {
  // background-color: blue;
  height: auto;
  position: absolute;
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z1010001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    .timeMsg {
      font-size: 1rem;
    }
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z1010001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
