<template>
  <div class="z1020001">
    <div class="essayBody">
      <p class="title c1">我的同学是如何年入百万的!?</p>
      <br />
      <br />
      总有人想知道如何赚钱，如何赚大钱，如何赚快钱，其实他们都理解错了赚钱的本质，赚钱最主要的是靠<i
        class="c2 big"
        >心态</i
      >！不然你去网络上面天天研究那些“如何年入百万”换来的都是叫你早点进入梦乡
      <br />
      <br />
      其实年入百万的方法非常简单，只需要你像我同学一样的心态同样可以实现<i
        class="c3 big"
        >年入百万！！</i
      >
      <br />
      <img src="./image/p8.jpg" alt="" class="meme" />
      <br />
      <br />
      只要你的心态是：路过一家奶茶店，突然想喝杯奶茶了，走进去一看，奶茶需要十块钱！我转身就离开。这时候只要你想：我今天没喝这杯奶茶我就赚了十块钱了！
      这时候恭喜你！<i class="c1 big">💴收入了第一桶金</i><br />
      这个方法不仅可以用在自己身上，还可以用在别人身上！
      当别人吃了一顿烧烤花了一百多块钱，而你没吃，这时候你又想：我没去吃那顿烧烤，我又赚了一百多块
      当你怀着这样的心态，那么恭喜你！你真的离百万富翁不远了！
      <br />
      【以上纯属开完笑，当然你也可以使用这个方法去实现年入百万！】
      <br />
      <img src="./image/p4.jpg" alt="" class="meme" />
      <br />
      <br />
      <br />
      说实话，如果学生想要真正的去赚到钱真的不简单，想要去干点兼职赚钱，但是学业繁重，课时多，不能干点全时兼职，想要干点符合专业知识的兼职，在学生时期的自己专业技术却又不算特别突出，实习也没人要。想要干点校内兼职，等半天才来一单的代拿代送，拼死拼活自行车轮子都骑冒烟了才赚那几块钱，想要干点网络兼职最后却沦为韭菜，被人割了又割
      <br />
      所以可能大多数的大学生来说，现在赚钱的最好方法也许真的仅限以下几种方式：
      <br /><br />
      1，通过参加专业内的竞赛去获奖或者提高自己的绩点然后去争取奖学金<br /><br />
      2，有资源的可以进入到自己的师兄或者老师的团队或者工作室去争取一点职位然后赚点生活费<br /><br />
      3，学习成绩好，专业能力突出的可以提前找到实习，不仅可以学习到工作经验而且能赚钱<br /><br />
      4，参加项目申报经费，我相信很多大学里面都有一些创新创业类大赛【<i
        class="c3"
        >PPT大赛</i
      >】这些比赛一般选中之后都会有一笔经费的，你可以用这笔经费去进行后续的研究【<i
        class="c2 big"
        >谁都说不定是不是用来研究呢😁</i
      >】这笔几千甚至上万的经费或许真挺不错的，当然这就需要你有一个好项目而且能找到一个团队了<i
        class="c4 big"
        >【当然也许我们就是天作之合】</i
      >
      <br /><br />5，通过校内兼职，还是那个自行车都抡冒烟的，如果不是特别有规划性的平台还是不建议【快有了！】
      <br />
      <br />
      所以，对于绝大多数大学生来说，或许好好学习真的赚钱的最好方法。
      <br />
      <img src="./image/p2.jpg" alt="" class="meme" />
      <br />
      <br />
      嗯？你是这样想的吗？那你真的就是个呆萌大学生了【和我这种一样了属于是】，曾经一位高人说过，我想我们真正赚钱需要的是眼光，如果你只能把眼光放在学校里面，放在学生里面，那么你肯定觉得对于学生赚钱来说就是好好学习或者当一些苦逼兼职，但是你已经是成年人了，把眼光放大一点，你甚至可以将眼光放到你们学校所在的城市之中，将一些混乱的东西整合起来【比如说把几所学校代拿代送做成规范化平台化，专门给城市里一些落后的小型企业提供技术转型服务（你的专业能够提供的服务）】比如我的师兄就会去给一些门店敲门给他们上门定制微信小程序，网站等各种定制服务
      <br />
      <br />
      当然，其实最后真正影响你赚钱的只是信息差，多和老师，师兄师姐，同学进行交流，不断的去学习接触不同的领域，打破越多的信息差，你的成功概率就越高！
      <br />

      <img src="./image/p1.jpg" alt="" class="meme" />
      <br />
      如果各位有想法也欢迎来和我交流，我也是非常期待和欢迎的😁说不定我们真的擦除了什么火花呢
      <i class="c1 big po"
        ><router-link
          :to="{
            name: 'fl',
            params: { cname: '欢迎页面', cid: 600, eid: 6000002 },
          }"
          class="rlColor"
          >点击此处和作者交流</router-link
        ></i
      >
      <br />
      <br />
      <br />
    </div>
    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
export default {
  name: "helloPage",
};
</script>

<style lang="less" scoped>
* {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.z1020001 {
  // background-color: blue;
  height: auto;
  position: absolute;
  line-height: 2.2rem;
  .meme {
    width: 30%;
    height: 30%;
    margin-left: 35%;
  }
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z1020001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z1020001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
