var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z6000001"},[_c('div',{staticClass:"essayBody"},[_c('p',{staticClass:"bigTitle"},[_vm._v("欢迎来到jaebook")]),_vm._v(" 当您踏入我的博客世界，仿佛踏入一座知识的花园，四季轮转，各具特色。这个世界被细致地分割成四大板块，每一块都是我心灵的一角，储藏着生活的点滴和学识的珍宝。 "),_c('br'),_c('br'),_vm._v(" 首先，"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '学习之路', cid: 101, eid: 1010001 },
        }}},[_vm._v("生活杂谈")])],1),_vm._v("板块犹如一汪清泉，涵盖"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '学习之路', cid: 101, eid: 1010001 },
        }}},[_vm._v("学习之路")])],1),_vm._v("、"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '生活日记', cid: 102, eid: 1020001 },
        }}},[_vm._v("生活日记")])],1),_vm._v("、"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '每日随笔', cid: 104, eid: 1040001 },
        }}},[_vm._v("每日随笔")])],1),_vm._v("和"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '产品评测', cid: 103, eid: 1030001 },
        }}},[_vm._v("产品测评")])],1),_vm._v("等内容。在这里，我将探讨学习的曲径通幽，记录生活的细枝末节，留存心灵的碎片，以及分享对产品的真挚体验。 "),_c('br'),_c('br'),_vm._v(" 其次，"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '编程笔记', cid: 200, eid: 2000001 },
        }}},[_vm._v("编程笔记")])],1),_vm._v("板块如同一本编织技艺的手札，记录着我在编程之路上的点滴心得。无论是代码的纷繁织网还是技术的花火绽放，我将在这里与您分享编程的乐趣与智慧，探索数字世界的奥秘。 "),_c('br'),_c('br'),_vm._v(" 另外，"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '知识碎片', cid: 301, eid: 3010001 },
        }}},[_vm._v("内容碎片")])],1),_vm._v("板块是一座知识的宝库，收纳了各种闪光的智慧之星。在这里，我将"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '知识碎片', cid: 301, eid: 3010001 },
        }}},[_vm._v("分享碎片化的知识点")])],1),_vm._v("和"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '资源分享', cid: 302, eid: 3020001 },
        }}},[_vm._v("珍贵资源")])],1),_vm._v("，为您提供一扇通往更广阔世界的窗户，让思想的火花在这里迸发。 "),_c('br'),_c('br'),_vm._v(" 最后，"),_c('i',{staticClass:"c1"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: { cname: '项目实战', cid: 400, eid: 4000001 },
        }}},[_vm._v("项目实战")])],1),_vm._v("板块是我的刀尖舞台，展示着实践的风采和智慧的结晶。我将分享自己在项目实战中的心路历程和经验总结，探讨创意的火花如何在实践中迸发，期待与您共同探讨创新的可能性。 "),_c('br'),_c('br'),_vm._v(" 在这片博客的海洋里，思想的船只穿行于知识的浪潮之间，随着每一篇文章的探索，我们将一同感受生活的温暖，品味学识的甘甜。欢迎您常来光顾，与我一同漫步于这个心灵的乐园，分享彼此的感悟与启迪。愿我们在这个虚拟的世界里，共同绽放着智慧的花朵，永不止息。 "),_c('br'),_c('br'),_c('p',{staticClass:"bigTitle"},[_vm._v("这是一本巨大的学习笔记")]),_c('p',{staticClass:"timeMsg"},[_vm._v("文章最后一次修改时间："+_vm._s(_vm.time))])]),_c('beianMsgVue',{staticClass:"beian"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }