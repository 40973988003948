<template>
  <div class="app">
    <div class="leftArea">
      <router-view>
        <contentFirstLevelListVue></contentFirstLevelListVue>
      </router-view>
    </div>
    <div class="middleArea">
      <contentMainBodyVue> </contentMainBodyVue>
    </div>
    <div class="rightArea">
      <contentSecondLevelListVue></contentSecondLevelListVue>
    </div>
  </div>
</template>

<script>
import contentFirstLevelListVue from "./contentFirstLevelList.vue";
import contentMainBodyVue from "./contentMainBody.vue";
import contentSecondLevelListVue from "./contentSecondLevelList.vue";
import Bus from "../utils/EventBus.js";
export default {
  components: {
    contentFirstLevelListVue,
    contentMainBodyVue,
    contentSecondLevelListVue,
  },
  created() {
    Bus.$on("showEid", (v) => {
      console.log(v);
    });
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  z-index: -1;
  position: absolute;
  // background-color: lightblue;
  display: flex;

  .leftArea {
    width: 20%;
    height: 100%;
    // background-color: pink;
    // position: fixed;
  }
  .middleArea {
    width: 60%;
    height: 100%;
    // background-color: red;
  }
  .rightArea {
    width: 20%;
    height: 100%;
    position: relative;
    background-color: white;
  }
}

@media only screen and (max-width: 600px) {
  .app {
    height: 92%;
    top: 8%;
    .rightArea {
      display: none;
    }
    .leftArea {
      display: none;
    }
  }
}

/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .app {
    height: 92%;
    top: 8%;
    .rightArea {
      display: none;
    }
    .leftArea {
      display: none;
    }
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) and (max-width: 1489px) {
  .app {
    height: 90%;
    top: 10%;
    .rightArea {
      display: block;
    }
    .leftArea {
      display: block;
    }
  }
}

@media only screen and (min-width: 1490px) {
  .app {
    height: 90%;
    top: 10%;
    .rightArea {
      display: block;
    }
    .leftArea {
      display: block;
    }
  }
}
</style>
