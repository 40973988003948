import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    learningEssay: [],
    diaryEssay: [],
    evaEssay: [],
    moodEssay: [],
    notesEssay: [],
    konwledgeEssay: [],
    resourceEssay: [],
    projectEssay: [],
    authorEssay: [],
    homePageEssay: [],
  },
  mutations: {
    changeEssay(state, msg) {
      // console.log(msg);
      var index = Math.floor(msg.essay_id / 10000);
      switch (index) {
        case 101:
          state.learningEssay.push(msg);
          break;
        case 102:
          state.diaryEssay.push(msg);
          break;
        case 103:
          state.evaEssay.push(msg);
          break;
        case 104:
          state.moodEssay.push(msg);
          break;
        case 200:
          state.notesEssay.push(msg);
          break;
        case 301:
          state.konwledgeEssay.push(msg);
          break;
        case 302:
          state.resourceEssay.push(msg);
          break;
        case 400:
          state.projectEssay.push(msg);
          break;
        case 500:
          state.authorEssay.push(msg);
          break;
        case 600:
          state.homePageEssay.push(msg);
      }
    },
  },
  actions: {},
  getters: {},
  modules: {},
});

export default store;
