import Vue from "vue";
import App from "./App.vue";

// vuex的导入
import store from "./store/index.js";

// 组件导入
import logoTextVue from "./components/logoText.vue";
import beianMsgVue from "./components/beianMsg.vue";

// 文章组件引入
// 使用字母代表数字，a1 b2 c3 d4 e5 f6 g7 h8 i9 j0 + 后缀Essay

// 600---欢迎页面
import fjjjjjaEssay from "./essayVue/fjjjjjaEssay/fjjjjjaEssay.vue";
import fjjjjjbEssay from "./essayVue/fjjjjjbEssay/fjjjjjbEssay.vue";

// 10x---生活杂谈
import ajajjjaEssay from "./essayVue/ajajjjaEssay/ajajjjaEssay.vue";
import ajbjjjaEssay from "./essayVue/ajbjjjaEssay/ajbjjjaEssay.vue";
import ajbjjjbEssay from "./essayVue/ajbjjjbEssay/ajbjjjbEssay.vue";
import ajcjjjaEssay from "./essayVue/ajcjjjaEssay/ajcjjjaEssay.vue";
import ajdjjjaEssay from "./essayVue/ajdjjjaEssay/ajdjjjaEssay.vue";
import ajdjjjbEssay from "./essayVue/ajdjjjbEssay/ajdjjjbEssay.vue";
import ajdjjjcEssay from "./essayVue/ajdjjjcEssay/ajdjjjcEssay.vue";

// 200---编程笔记
import bjjjjjaEssay from "./essayVue/bjjjjjaEssay/bjjjjjaEssay.vue";
import bjjjjjbEssay from "./essayVue/bjjjjjbEssay/bjjjjjbEssay.vue";
import bjjjjjcEssay from "./essayVue/bjjjjjcEssay/bjjjjjcEssay.vue";

// 30x---内容碎片
import cjajjjaEssay from "./essayVue/cjajjjaEssay/cjajjjaEssay.vue";
import cjajjjbEssay from "./essayVue/cjajjjbEssay/cjajjjbEssay.vue";
import cjajjjcEssay from "./essayVue/cjajjjcEssay/cjajjjcEssay.vue";
import cjajjjdEssay from "./essayVue/cjajjjdEssay/cjajjjdEssay.vue";
import cjajjjeEssay from "./essayVue/cjajjjeEssay/cjajjjeEssay.vue";
import cjbjjjaEssay from "./essayVue/cjbjjjaEssay/cjbjjjaEssay.vue";
import cjbjjjbEssay from "./essayVue/cjbjjjbEssay/cjbjjjbEssay.vue";

// 400---项目实战
import djjjjjaEssay from "./essayVue/djjjjjaEssay/djjjjjaEssay.vue";
import djjjjjbEssay from "./essayVue/djjjjjbEssay/djjjjjbEssay.vue";

// 500---作者
import ejjjjjaEssay from "./essayVue/ejjjjjaEssay/ejjjjjaEssay.vue";

// 路由的导入
import router from "./router/index";

Vue.component("logoTextVue", logoTextVue);
Vue.component("beianMsgVue", beianMsgVue);

// 文章组件的注册
Vue.component("fjjjjjaEssay", fjjjjjaEssay);
Vue.component("ajajjjaEssay", ajajjjaEssay);
Vue.component("ajbjjjaEssay", ajbjjjaEssay);
Vue.component("ajbjjjbEssay", ajbjjjbEssay);
Vue.component("ajcjjjaEssay", ajcjjjaEssay);
Vue.component("ajdjjjaEssay", ajdjjjaEssay);
Vue.component("ajdjjjbEssay", ajdjjjbEssay);
Vue.component("ajdjjjcEssay", ajdjjjcEssay);
Vue.component("bjjjjjaEssay", bjjjjjaEssay);
Vue.component("bjjjjjbEssay", bjjjjjbEssay);
Vue.component("bjjjjjcEssay", bjjjjjcEssay);
Vue.component("cjajjjaEssay", cjajjjaEssay);
Vue.component("cjajjjbEssay", cjajjjbEssay);
Vue.component("cjajjjcEssay", cjajjjcEssay);
Vue.component("cjajjjdEssay", cjajjjdEssay);
Vue.component("cjajjjeEssay", cjajjjeEssay);
Vue.component("cjbjjjaEssay", cjbjjjaEssay);
Vue.component("cjbjjjbEssay", cjbjjjbEssay);
Vue.component("djjjjjaEssay", djjjjjaEssay);
Vue.component("djjjjjbEssay", djjjjjbEssay);
Vue.component("ejjjjjaEssay", ejjjjjaEssay);
Vue.component("fjjjjjbEssay", fjjjjjbEssay);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
