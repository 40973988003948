var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"firstLevelList"},[_c('p',{staticClass:"catalogueName"},[_vm._v(_vm._s(_vm.catalogueName))]),_c('ul',{staticClass:"catalogue"},_vm._l((_vm.essay),function(item,index){return _c('li',{key:index,staticClass:"essayName"},[_c('router-link',{staticClass:"rlColor",attrs:{"to":{
          name: 'fl',
          params: {
            cname: _vm.catalogueName,
            cid: _vm.id,
            eid: item.essay_id,
          },
        }}},[_vm._v(" "+_vm._s(item.essay_title)+" ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }