<template>
  <div class="app">
    <ul class="firstLevelList">
      <li
        @mousemove="topNavLevelListOnFocus_1"
        @mouseout="topNavLevelListOnBlur_1"
      >
        生活杂谈
        <ul class="secondLevelList" v-show="topNavLevelListIsShow_1">
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '学习之路', cid: 101, eid: 1010001 },
              }"
              class="rlColor"
            >
              🚀学习之路
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '生活日记', cid: 102, eid: 1020001 },
              }"
              class="rlColor"
            >
              📆生活日记
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '产品评测', cid: 103, eid: 1030001 },
              }"
              class="rlColor"
            >
              🤖产品测评
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '每日随笔', cid: 104, eid: 1040001 },
              }"
              class="rlColor"
              >🎧每日随笔
            </router-link>
          </li>
        </ul>
      </li>
      <li
        @mousemove="topNavLevelListOnFocus_2"
        @mouseout="topNavLevelListOnBlur_2"
      >
        <router-link
          :to="{
            name: 'fl',
            params: { cname: '编程笔记', cid: 200, eid: 2000001 },
          }"
          class="rlColor"
        >
          编程笔记
        </router-link>
        <ul class="secondLevelList" v-show="topNavLevelListIsShow_2">
          <li v-for="(item, index) in notesEssay_topList" :key="index">
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '编程笔记', cid: 200, eid: item.essay_id },
              }"
              class="rlColor"
            >
              {{ item.essay_title }}
            </router-link>
          </li>
        </ul>
      </li>
      <li
        @mousemove="topNavLevelListOnFocus_3"
        @mouseout="topNavLevelListOnBlur_3"
      >
        内容碎片
        <ul class="secondLevelList" v-show="topNavLevelListIsShow_3">
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '知识碎片', cid: 301, eid: 3010001 },
              }"
              class="rlColor"
              >📖知识碎片</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '资源分享', cid: 302, eid: 3020001 },
              }"
              class="rlColor"
              >🌍资源分享</router-link
            >
          </li>
        </ul>
      </li>
      <li
        @mousemove="topNavLevelListOnFocus_4"
        @mouseout="topNavLevelListOnBlur_4"
      >
        <router-link
          :to="{
            name: 'fl',
            params: { cname: '项目实战', cid: 400, eid: 4000001 },
          }"
          class="rlColor"
        >
          项目实战
        </router-link>
        <ul class="secondLevelList" v-show="topNavLevelListIsShow_4">
          <li v-for="(item, index) in projectEssay_topList" :key="index">
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '项目实战', cid: 400, eid: item.essay_id },
              }"
              class="rlColor"
            >
              {{ item.essay_title }}
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link
          :to="{
            name: 'fl',
            params: { cname: '作者', cid: 500, eid: 5000001 },
          }"
          class="rlColor"
          >作者</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Bus from "../utils/EventBus";
export default {
  methods: {
    topNavLevelListOnFocus_1() {
      this.topNavLevelListIsShow_1 = true;
    },
    topNavLevelListOnBlur_1() {
      this.topNavLevelListIsShow_1 = false;
    },
    topNavLevelListOnFocus_2() {
      this.topNavLevelListIsShow_2 = true;
    },
    topNavLevelListOnBlur_2() {
      this.topNavLevelListIsShow_2 = false;
    },
    topNavLevelListOnFocus_3() {
      this.topNavLevelListIsShow_3 = true;
    },
    topNavLevelListOnBlur_3() {
      this.topNavLevelListIsShow_3 = false;
    },
    topNavLevelListOnFocus_4() {
      this.topNavLevelListIsShow_4 = true;
    },
    topNavLevelListOnBlur_4() {
      this.topNavLevelListIsShow_4 = false;
    },
  },
  data() {
    return {
      topNavLevelListIsShow_1: false,
      topNavLevelListIsShow_2: false,
      topNavLevelListIsShow_3: false,
      topNavLevelListIsShow_4: false,
      notesEssay_topList: [],
      projectEssay_topList: [],
      learningEssay_topList: [],
      diaryEssay_topList: [],
      evaEssay_topList: [],
      moodEssay_topList: [],
      konwledgeEssay_topList: [],
      resourceEssay_topList: [],
      authorEssay_topList: [],
      allEssay: [],
    };
  },
  mounted() {
    const time1 = setInterval(() => {
      if (this.$store.state.notesEssay.length) {
        clearInterval(time1);
        this.notesEssay_topList = this.$store.state.notesEssay;
        this.projectEssay_topList = this.$store.state.projectEssay;
        this.learningEssay_topList = this.$store.state.learningEssay;
        this.diaryEssay_topList = this.$store.state.diaryEssay;
        this.evaEssay_topList = this.$store.state.evaEssay;
        this.moodEssay_topList = this.$store.state.moodEssay;
        this.konwledgeEssay_topList = this.$store.state.konwledgeEssay;
        this.resourceEssay_topList = this.$store.state.resourceEssay;
        this.authorEssay_topList = this.$store.state.authorEssay;
        this.allEssay.push(this.$store.state.notesEssay);
        this.allEssay.push(this.$store.state.projectEssay);
        this.allEssay.push(this.$store.state.learningEssay);
        this.allEssay.push(this.$store.state.diaryEssay);
        this.allEssay.push(this.$store.state.evaEssay);
        this.allEssay.push(this.$store.state.moodEssay);
        this.allEssay.push(this.$store.state.konwledgeEssay);
        this.allEssay.push(this.$store.state.resourceEssay);
        this.allEssay.push(this.$store.state.authorEssay);
        // console.log(this.allEssay);
        Bus.$emit("allEssay", this.allEssay);
      }
    }, 300);
    // 获取编程笔记的文章和项目实战的文章
  },
};
</script>

<style lang="less" scoped>
.rlColor {
  color: black;
  &:hover {
    color: green;
  }
}
.lac {
  color: black;
}
.laec {
  color: black;
}
.app {
  position: absolute;
  width: 540px;
  height: 50px;

  // background-color: blue;
  .firstLevelList {
    & > :nth-child(1) {
      &::before {
        display: inline-block;
        content: "☕";
        font-size: 14px;
      }
    }
    & > :nth-child(2) {
      &::before {
        display: inline-block;
        content: "🖊";
        font-size: 14px;
      }
    }
    & > :nth-child(3) {
      &::before {
        display: inline-block;
        content: "⭐";
        font-size: 14px;
      }
    }
    & > :nth-child(4) {
      &::before {
        display: inline-block;
        content: "💻";
        font-size: 14px;
      }
    }
    & > :nth-child(5) {
      &::before {
        display: inline-block;
        content: "📕";
        font-size: 14px;
      }
    }
  }
  .firstLevelList > li {
    cursor: pointer;
    float: left;
    margin: 10px 10px;
    font-size: 18px;
    font-weight: normal;

    .secondLevelList {
      margin-top: 3px;
      // margin-left: 10px;
      position: absolute;
      max-height: 300px;
      width: 200px;
      background-color: white;
      border: 1px solid black;
      overflow: hidden;
      & > li {
        margin: 20px 10px;
        font-size: 16px;
        &:hover {
          color: green;
        }
      }
    }
  }
}
</style>
