<template>
  <div>
    <div>
      <i
        class="lable"
        @click="onClick"
        :style="{ fontSize: lable_size, marginTop: lable_top }"
        >请输入联系方式</i
      >
      <input
        type="text"
        ref="Input"
        class="contact"
        v-model="contact_value"
        :style="{ color: contact_color, border: contact_border }"
        @focus="onClick"
        @blur="onBlur"
      />
    </div>
    <div>
      <i
        class="lableA"
        @click="onClickA"
        :style="{ fontSize: lableA_size, marginTop: lableA_top }"
        >请输入联系内容</i
      >
      <Button
        plain
        hairline
        type="primary"
        v-show="showClear"
        @click="clearContent"
        style="position: absolute; height: 3%; margin-top: 1%; margin-left: 74%"
        >清空</Button
      >
      <textarea
        ref="Textarea"
        v-model="content_value"
        @focus="onClickA"
        @blur="onBlurA"
        :style="{ border: content_border }"
      ></textarea>
    </div>
    <div class="buttonArea">
      <Button type="primary" class="submit" @click="submitMsg">发送信息</Button>
      <Button type="info" plain class="submit" @click="toMsg">获取信息</Button>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from "vant";
import api from "@/api";
export default {
  components: {
    Button,
  },
  data() {
    return {
      contact_value: "",
      contact_color: "#716f6f",
      contact_border: "1px solid black",
      lable_size: "1.2rem",
      lable_top: " 1.2%",
      content_value: "",
      lableA_size: "1.2rem",
      lableA_top: " 1.5%",
      content_border: "1px solid black",
      reply: "",
      showClear: false,
    };
  },
  methods: {
    onClick() {
      if (this.contact_value == "") {
        this.contact_color = "black";
        this.contact_value = "";
        this.contact_border = "1px solid green";
        this.lable_size = "0.9rem";
        this.lable_top = "-1.2rem";
        this.$refs.Input.focus();
      }
    },
    onBlur() {
      if (this.contact_value == "") {
        this.contact_color = "#716f6f";
        this.contact_border = "1px solid black";
        this.lable_size = "1.2rem";
        this.lable_top = "1.2%";
      }
    },
    onClickA() {
      if (this.content_value == "") {
        this.content_value = "";
        this.content_border = "1px solid green";
        this.lableA_size = "0.9rem";
        this.lableA_top = "-1.2rem";
        this.$refs.Textarea.focus();
      }
    },
    onBlurA() {
      if (this.content_value == "") {
        this.content_border = "1px solid black";
        this.lableA_size = "1.2rem";
        this.lableA_top = "1.5%";
      }
    },
    getCurrentDateTime() {
      var currentDateTime = new Date();

      var year = currentDateTime.getFullYear();
      var month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
      var day = String(currentDateTime.getDate()).padStart(2, "0");
      var hours = String(currentDateTime.getHours()).padStart(2, "0");
      var minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
      var seconds = String(currentDateTime.getSeconds()).padStart(2, "0");

      var formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDateTime;
    },
    submitMsg() {
      // 发送信息：
      if (this.contact_value == "") {
        Toast.fail("请输入联系方式");
      } else {
        if (this.content_value == "") {
          Toast.fail("请输入联系内容");
        } else {
          // 发送请求
          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
          var msg = {
            interact_content: this.content_value,
            interact_contact: this.contact_value,
            interact_time: this.getCurrentDateTime(),
          };
          api
            .post("admin/sentmsg", msg, config)
            .then((res) => {
              console.log(res);
              if (res.data == "发送成功") {
                Toast.success("发送成功");
                this.content_value = "";
                this.contact_value = "";
              }
            })
            .catch((err) => {
              console.log(err);
              Toast.fail(err);
            });
        }
      }
    },
    toMsg() {
      if (this.contact_value == "") {
        Toast.fail("请输入联系方式");
      } else {
        // 发送请求
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        var msg = {
          interact_contact: this.contact_value,
        };
        api
          .post("admin/tomsg", msg, config)
          .then((res) => {
            if (res.data == "") {
              Toast.fail(
                "该联系方式暂时没有查询到作者回复！正在催作者回复/(ㄒoㄒ)/~~"
              );
            } else {
              var replyNum = 0;
              this.content_value = "";
              this.reply = "";
              for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].interact_reply) {
                  replyNum++;
                  this.onClickA();
                  this.content_value += `你：${res.data[i].interact_content}\n作者：${res.data[i].interact_reply}\n`;
                  this.reply += `你：${res.data[i].interact_content}\n作者：${res.data[i].interact_reply}\n`;
                }
              }
              if (replyNum == 0) {
                Toast.fail(
                  "该联系方式暂时没有查询到作者回复！正在催作者回复/(ㄒoㄒ)/~~"
                );
              } else {
                Toast.success(`查询成功！总共查询到${replyNum}条回复`);
                this.addMsg();
                // console.log(this.reply);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addMsg() {
      let index = 0;
      this.content_value = "";
      const intervalId = setInterval(() => {
        if (index < this.reply.length) {
          // 将每个字符逐个追加到文本域内容中
          this.content_value += this.reply[index];
          index++;
        } else {
          clearInterval(intervalId); // 清除定时器
        }
      }, 100);
    },
    clearContent() {
      this.content_value = "";
    },
  },
  watch: {
    content_value(news) {
      if (news != "") {
        this.showClear = true;
      } else {
        this.showClear = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lableA {
  position: absolute;
  background-color: white;
  color: #716f6f;
  margin-left: 13%;
  transition: all 0.3s;
}
textarea {
  box-sizing: border-box;
  resize: none;
  width: 80%;
  height: 20rem;
  padding: 1.5rem;
  font-size: 1.2rem;
  margin-left: 10%;
}

.lable {
  // background-color: blue;
  position: absolute;
  background-color: white;
  color: #716f6f;
  margin-left: 13%;
  transition: all 0.3s;
}
.contact {
  box-sizing: border-box;
  width: 80%;
  // color: ;
  height: 1.4rem;
  margin-left: 10%;
  font-size: 1.2rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: all 0.5s;
}
.buttonArea {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  .submit {
    width: 40%;
  }
}
</style>
