<template>
  <div class="app">
    <div class="input_area">
      <icon name="search" class="icon_search" />
      <input
        type="text"
        @click="haveInput"
        @blur="haveOutput"
        :style="{ border: I_border }"
        v-model="I_value"
      />
    </div>
    <div class="result_area" v-show="result_isShow">
      <ul>
        <li v-for="(item, index) in results" :key="index">
          {{ item.essay_topic + ">>" }}
          <router-link
            :to="{
              name: 'fl',
              params: {
                cname: item.essay_topic,
                cid: item.essay_fatherId,
                eid: item.essay_id,
              },
            }"
            class="rlColor"
          >
            <i v-html="result[index]" @click="clear"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Bus from "../utils/EventBus";
import icon from "vant/lib/icon";
import "vant/lib/icon/style";
import api from "@/api";
import qs from "qs";
// import api from "@/api";
export default {
  components: {
    icon,
  },
  methods: {
    haveInput() {
      this.I_border = "1px solid green";
    },
    haveOutput() {
      if (!this.I_value) {
        this.I_border = "1px solid #cfd4db";
        this.result_isShow = false;
      }
    },
    clear() {
      this.I_value = "";
    },
  },
  data() {
    return {
      I_border: "1px solid #cfd4db",
      I_value: "",
      // 调用axios，当有答案的时候就展示出来
      result_isShow: false,
      allEssay: [],
      results: [],
      result: [],
    };
  },
  watch: {
    I_value(newValue) {
      if (newValue != "") {
        this.result_isShow = true;
        setTimeout(() => {
          // 从vuex中匹配数据
          console.log(newValue);
          const requestData = {
            key: newValue,
          };
          const formData = qs.stringify(requestData);
          api
            .post("/essay/matching", formData, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then((res) => {
              // console.log(res.data);
              this.result.length = 0;
              this.results = res.data;
              for (var i = 0; i < this.results.length; i++) {
                var temp = "";
                for (
                  var j = 0;
                  j < this.results[i].essay_title.split(newValue).length;
                  j += 2
                ) {
                  if (
                    j + 1 <
                    this.results[i].essay_title.split(newValue).length
                  ) {
                    // console.log(this.results[i].essay_title.split(newValue)[j]);
                    temp += `${
                      this.results[i].essay_title.split(newValue)[j]
                    }<strong class="keyword">${newValue}</strong>${
                      this.results[i].essay_title.split(newValue)[j + 1]
                    }`;
                  } else {
                    // console.log(this.results[i].essay_title);
                    temp += `${
                      this.results[i].essay_title.split(newValue)[j]
                    }<strong class="keyword">${newValue}</strong>
                  `;
                  }
                }
                this.result.push(temp);
                console.log(temp);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }, 500);
      } else {
        this.result_isShow = false;
      }
    },
  },
  created() {
    Bus.$on("allEssay", (msg) => {
      this.allEssay = msg;
    });
  },
};
</script>

<style lang="less" scoped>
.app {
  position: absolute;
  width: 400px;
  // background-color: red;
  height: 60px;
  .input_area {
    width: 350px;
    height: 50px;
    // background-color: blue;
    margin-top: 5px;
    margin-left: 25px;
    .icon_search {
      position: absolute;
      margin-top: 15px;
      margin-left: 10px;
      font-size: 23px;
    }

    input {
      transition: all 0.5s;
      outline: none;
      width: 350px;
      height: 50px;
      border-radius: 25px;
      font-size: 18px;
      padding: 0 15px;
      padding-left: 38px;
    }
  }
  .result_area {
    width: 350px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid black;
    border-top: 1px solid green;
    background-color: white;
    margin-left: 50px;
    margin-top: 2px;
    ul {
      list-style: none;
      li {
        font-size: 16px;
        margin: 10px 5px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        i {
          font-style: normal;
          color: black;
          &:hover {
            color: green;
          }
        }
        .keyword {
          font-style: normal;
        }
      }
    }
  }
}
</style>
