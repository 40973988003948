<template>
  <div class="z3010001">
    <div class="essayBody">
      <p class="title c1">魔法?</p>
      <br />
      <br />
      <p>
        先<a
          href="https://www.dogfight360.com/blog/686/"
          class="c1 big"
          target="_blank"
          title="点击跳转下载steamcommunity"
          >下载steamcommunity</a
        >然后移步<a
          href="https://github.com/shadowrocketHelp/help"
          target="_blank"
          title="点击跳转教程地址"
          >Github搜索教程xx上网</a
        >
      </p>
      <br />
      <br />
      <p class="cen c1 big">记得好好学习</p>
    </div>
    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
export default {
  name: "helloPage",
};
</script>

<style lang="less" scoped>
* {
  font-family: Arial, Helvetica, sans-serif;
}
.z3010001 {
  // background-color: blue;
  height: auto;
  position: absolute;
  line-height: 2.3rem;
}
/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z3010001 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z3010001 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
