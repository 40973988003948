<template>
  <div id="beian">
    <div class="topMsg">
      <ul>
        <li>数维网络</li>
        <li>作者-jae</li>
        <li>商务合作</li>
        <li>
          <router-link
            :to="{
              name: 'fl',
              params: { cname: '欢迎页面', cid: 600, eid: 6000002 },
            }"
            class="rlColor"
            >联系作者</router-link
          >
        </li>
      </ul>
    </div>
    <div class="bottomMsg">
      <p>
        2024@jaebook |
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          class="beiantext"
          >粤ICP备2024206776号-1</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
a {
  color: black;
  &:hover {
    color: green;
  }
}
.beiantext {
  color: green;
}
#beian {
  height: 8rem;
  background-color: rgb(233, 230, 230);
  position: absolute;
  .topMsg {
    // background-color: red;
    // width: 100%;
    height: 5rem;
    ul {
      display: flex;
      li {
        width: 20%;
        margin: 1rem 2.5%;
        text-align: center;
        // background-color: blue;
        cursor: pointer;
        float: left;
        font-family: Arial, Helvetica, sans-serif;
        // font-weight: ;
        &:hover {
          color: green;
        }
      }
    }
  }
  .bottomMsg {
    // background-color: yellow;
    width: 100%;
    height: 3rem;
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  #beian {
    width: 100%;
    .topMsg {
      font-size: 14px;
    }
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  #beian {
    width: 100%;
    .topMsg {
      font-size: 18px;
    }
  }
}
</style>
