<template>
  <div class="mb">
    <fjjjjjaEssay v-show="nowEssayId == 6000001"></fjjjjjaEssay>
    <fjjjjjbEssay v-show="nowEssayId == 6000002"></fjjjjjbEssay>
    <ajajjjaEssay v-show="nowEssayId == 1010001"></ajajjjaEssay>
    <ajbjjjaEssay v-show="nowEssayId == 1020001"></ajbjjjaEssay>
    <ajbjjjbEssay v-show="nowEssayId == 1020002"></ajbjjjbEssay>
    <ajcjjjaEssay v-show="nowEssayId == 1030001"></ajcjjjaEssay>
    <ajdjjjaEssay v-show="nowEssayId == 1040001"></ajdjjjaEssay>
    <ajdjjjbEssay v-show="nowEssayId == 1040002"></ajdjjjbEssay>
    <ajdjjjcEssay v-show="nowEssayId == 1040003"></ajdjjjcEssay>
    <bjjjjjaEssay v-show="nowEssayId == 2000001"></bjjjjjaEssay>
    <bjjjjjbEssay v-show="nowEssayId == 2000002"></bjjjjjbEssay>
    <bjjjjjcEssay v-show="nowEssayId == 2000003"></bjjjjjcEssay>
    <cjajjjaEssay v-show="nowEssayId == 3010001"></cjajjjaEssay>
    <cjajjjbEssay v-show="nowEssayId == 3010002"></cjajjjbEssay>
    <cjajjjcEssay v-show="nowEssayId == 3010003"></cjajjjcEssay>
    <cjajjjdEssay v-show="nowEssayId == 3010004"></cjajjjdEssay>
    <cjajjjeEssay v-show="nowEssayId == 3010005"></cjajjjeEssay>
    <cjbjjjaEssay v-show="nowEssayId == 3020001"></cjbjjjaEssay>
    <cjbjjjbEssay v-show="nowEssayId == 3020002"></cjbjjjbEssay>
    <djjjjjaEssay v-show="nowEssayId == 4000001"></djjjjjaEssay>
    <djjjjjbEssay v-show="nowEssayId == 4000002"></djjjjjbEssay>
    <ejjjjjaEssay v-show="nowEssayId == 5000001"></ejjjjjaEssay>
  </div>
</template>

<script>
import Bus from "@/utils/EventBus";
export default {
  data() {
    return {
      nowEssayId: 0,
    };
  },
  created() {
    Bus.$on("essay", (v) => {
      // console.log(v.essay_id);
      this.nowEssayId = v.essay_id;
    });
  },
};
</script>

<style lang="less" scoped>
.mb {
  // background-color: lightblue;
  width: 100%;
  height: auto;
}
</style>
