<template>
  <div id="logo" @click="toHome">
    <router-link
      :to="{
        name: 'fl',
        params: { cname: '欢迎来到jaebook', cid: 600, eid: 6000001 },
      }"
      class="rlColor"
      >Jaebook</router-link
    >
  </div>
</template>

<script>
export default {
  methods: {
    toHome() {},
  },
};
</script>
<style lang="less" scoped>
#logo {
  position: absolute;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  .rlColor {
    background: linear-gradient(to right, #02b32c, #0099a4);
    background-clip: text;
    color: transparent;
  }
}
</style>
