<template>
  <div class="z6000002">
    <div class="essayBody">
      <p class="title c1">交互器</p>
      <br />
      <br />
      <p class="cen c1">
        提交内容时记得填写联系方式！查询作者对你的回复需要根据联系方式来查询！
      </p>
      <p class="cen tip">
        获取信息的方法：输入你发送信息的联系方式，然后点击获取信息，即可获取作者对你的回信
      </p>
      <div class="interactArea">
        <interactAreaVue class="uup1"></interactAreaVue>
      </div>

      <!-- <p class="timeMsg">文章最后一次修改时间：{{ time }}</p> -->
    </div>
    <beianMsgVue></beianMsgVue>
  </div>
</template>

<script>
import Bus from "../../utils/EventBus.js";
import interactAreaVue from "@/components/interactTool.vue";
export default {
  name: "helloPage",
  components: {
    interactAreaVue,
  },
  data() {
    return {
      time: "",
    };
  },
  created() {
    Bus.$on("essay", (s) => {
      const dbTime = s.essay_publicTime;

      // 创建一个新的 Date 对象，传入数据库获取的时间字符串
      const date = new Date(dbTime);

      // 使用 Date 对象的方法获取本地时间
      this.time = date.toLocaleString();
    });
  },
};
</script>

<style lang="less" scoped>
.z6000002 {
  // background-color: red;
  height: auto;
  position: absolute;
  line-height: 2rem;
  .interactArea {
    width: 100%;
    height: 33rem;
    margin-top: 2rem;
    // background-color: blue;
    .uup1 {
      left: 10%;
      top: 10%;
    }
  }
}

/* 对于屏幕宽度介于 600px 和 900px 之间的设备应用以下样式 */
@media only screen and (max-width: 1024px) {
  .z6000002 {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
  }
}

/* 对于屏幕宽度大于 1025px 的设备应用以下样式 */
@media only screen and (min-width: 1025px) {
  .z6000002 {
    width: 55%;
    margin-left: 2.5%;
    margin-top: 1%;
  }
}
</style>
