import Vue from "vue";
import vueRouter from "vue-router";
import contentFirstLevelListVue from "../components/contentFirstLevelList.vue";
Vue.use(vueRouter);
const router = new vueRouter({
  routes: [
    {
      name: "fl",
      path: "/firstLevelList/:cname/:cid/:eid",
      component: contentFirstLevelListVue,
    },

    {
      path: "/",
      redirect: "/firstLevelList/欢迎来到jaebook/600/6000001",
    },

    // { path: "/mainBody", component: contentMainBodyVue },
    {
      path: "*",
      redirect: "/firstLevelList/欢迎来到jaebook/600/6000001",
    },
  ],
  linkActiveClass: "lac",
  linkExactActiveClass: "leac",
});
export default router;
