<template>
  <div class="app1">
    <collapse v-model="activeName" accordion class="collapsePage">
      <collapseItem title="☕生活杂谈" name="1" size="large">
        <collapse v-model="activeName_s" accordion>
          <collapseItem title="🚀学习之路">
            <ul class="learning thirdLevelList">
              <li
                v-for="(item, index) in learningEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '学习之路', cid: 101, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
          <collapseItem title="📆生活日记">
            <ul class="diary thirdLevelList">
              <li
                v-for="(item, index) in diaryEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '生活日记', cid: 102, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
          <collapseItem title="🤖产品评测">
            <ul class="eva thirdLevelList">
              <li
                v-for="(item, index) in evaEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '产品评测', cid: 103, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
          <collapseItem title="🎧每日随笔">
            <ul class="mood thirdLevelList">
              <li
                v-for="(item, index) in moodEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '每日随笔', cid: 104, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
        </collapse>
      </collapseItem>
      <collapseItem title="🖊编程笔记" name="2" size="large">
        <collapse v-model="activeName_s" accordion>
          <collapseItem
            :title="item.essay_title"
            v-for="(item, index) in notesEssay_mobList"
            :key="index"
          >
            <ul style="list-style: none" class="thirdLevelList">
              <li
                v-for="(item_, index_) in dealData(item.essay_sonLevelList)"
                class="cataSonItem"
                :key="index_"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '编程笔记', cid: 200, eid: item.essay_id },
                  }"
                  class="rlColor2"
                >
                  {{ item_ }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
        </collapse>
      </collapseItem>
      <collapseItem title="⭐内容碎片" name="3" size="large">
        <collapse v-model="activeName_s" accordion>
          <collapseItem title="📖知识碎片">
            <ul class="konwledge thirdLevelList">
              <li
                v-for="(item, index) in konwledgeEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '知识碎片', cid: 301, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
          <collapseItem title="🌍资源分享">
            <ul class="resource thirdLevelList">
              <li
                v-for="(item, index) in resourceEssay_mobList"
                :key="index"
                @click="demo"
              >
                <router-link
                  :to="{
                    name: 'fl',
                    params: { cname: '资源分享', cid: 302, eid: item.essay_id },
                  }"
                  class="rlColor"
                >
                  {{ item.essay_title }}
                </router-link>
              </li>
            </ul>
          </collapseItem>
        </collapse>
      </collapseItem>
      <collapseItem title="💻项目实战" name="4" size="large">
        <!-- <collapse v-model="activeName_s" accordion>
          <collapseItem
            :title="item.essay_title"
            v-for="(item, index) in projectEssay_mobList"
            :key="index"
          >
            <ul class="thirdLevelList"></ul>
          </collapseItem>
        </collapse> -->
        <ul class="thirdLevelList">
          <li
            v-for="(item, index) in projectEssay_mobList"
            :key="index"
            class="SecondListItem"
            @click="demo"
          >
            <router-link
              :to="{
                name: 'fl',
                params: { cname: '项目实战', cid: 400, eid: item.essay_id },
              }"
              class="rlColor"
            >
              {{ item.essay_title }}
            </router-link>
          </li>
        </ul>
      </collapseItem>
      <collapseItem title="📕作者" name="5" size="large">
        <collapse v-model="activeName_s" accordion>
          <ul style="list-style: none" class="thirdLevelList">
            <li
              v-for="(item, index) in authorEssay_mobList"
              :key="index"
              class="SecondListItem"
              @click="demo"
            >
              <router-link
                :to="{
                  name: 'fl',
                  params: { cname: '作者', cid: 500, eid: item.essay_id },
                }"
                class="rlColor"
              >
                {{ item.essay_title }}
              </router-link>
            </li>
            <!-- <collapseItem title="📱社交媒体">
            <ul class="thirdLevelList"></ul>
          </collapseItem>
          <collapseItem title="👾个人作品">
            <ul class="thirdLevelList"></ul>
          </collapseItem>
          <collapseItem title="💬联系作者">
            <ul class="thirdLevelList"></ul>
          </collapseItem> -->
          </ul>
        </collapse>
      </collapseItem>
    </collapse>
  </div>
</template>

<script>
import collapse from "vant/lib/collapse";
import "vant/lib/collapse/style";
import collapseItem from "vant/lib/collapse-item";
import "vant/lib/collapse-item/style";
export default {
  components: {
    collapse,
    collapseItem,
  },
  data() {
    return {
      activeName: "1",
      activeName_s: "2",
      learningEssay_mobList: [],
      diaryEssay_mobList: [],
      evaEssay_mobList: [],
      moodEssay_mobList: [],
      notesEssay_mobList: [],
      konwledgeEssay_mobList: [],
      resourceEssay_mobList: [],
      projectEssay_mobList: [],
      authorEssay_mobList: [],
      allEssay: [],
    };
  },
  mounted() {
    const time1 = setInterval(() => {
      // console.log(this.$store.state.notesEssay.length);
      if (this.$store.state.notesEssay.length) {
        clearInterval(time1);
        this.notesEssay_mobList = this.$store.state.notesEssay;
        this.projectEssay_mobList = this.$store.state.projectEssay;
        this.learningEssay_mobList = this.$store.state.learningEssay;
        this.diaryEssay_mobList = this.$store.state.diaryEssay;
        this.evaEssay_mobList = this.$store.state.evaEssay;
        this.moodEssay_mobList = this.$store.state.moodEssay;
        this.konwledgeEssay_mobList = this.$store.state.konwledgeEssay;
        this.resourceEssay_mobList = this.$store.state.resourceEssay;
        this.authorEssay_mobList = this.$store.state.authorEssay;
        // this.allEssay.push(this.$store.state.notesEssay);
        // this.allEssay.push(this.$store.state.projectEssay);
        // this.allEssay.push(this.$store.state.learningEssay);
        // this.allEssay.push(this.$store.state.diaryEssay);
        // this.allEssay.push(this.$store.state.evaEssay);
        // this.allEssay.push(this.$store.state.moodEssay);
        // this.allEssay.push(this.$store.state.konwledgeEssay);
        // this.allEssay.push(this.$store.state.resourceEssay);
        // this.allEssay.push(this.$store.state.authorEssay);
        // console.log(this.allEssay);
      }
    }, 300);
    // 获取编程笔记的文章和项目实战的文章
  },
  methods: {
    dealData(demo) {
      if (!demo) {
        return "";
      }
      const arr = demo.split("|");
      // console.log(arr);
      let cata = [];
      for (var i of arr) {
        let a = i.split("-");
        cata.push(a[1]);
      }
      return cata;
    },
    demo() {
      this.$emit("changePopupShow", false);
      // this.$refs.cata.toggle();
      // console.log();
    },
  },
};
</script>

<style lang="less" scoped>
a {
  color: black;
}
.lac {
  color: green;
  font-weight: bold;
}
.rlColor2 {
  color: rgb(93, 91, 91);
}
.app1 {
  margin-top: 5vh;
  margin-left: 5%;
  position: Flexbox;
  width: 80%;
  .cataSonItem {
    color: rgb(107, 93, 93);
    cursor: pointer;
    margin: 15px 2px;
  }
  .thirdLevelList {
    list-style: none;
    li {
      padding: 5px 10px;
      margin: 10px 0;
      font-size: 14px;
      color: black;
      // background-color: blue;
      cursor: pointer;
      white-space: normal; /* 指定如何处理元素内的空白 */
      overflow-wrap: break-word; /* 在必要时进行单词内换行 */
      &:hover {
        color: green;
      }
    }
  }
  .SecondListItem {
    padding: 3px 10px;
    margin: 10px 0;
    font-size: 14px;
    color: black;
    cursor: pointer;
    white-space: normal; /* 指定如何处理元素内的空白 */
    overflow-wrap: break-word; /* 在必要时进行单词内换行 */
    &:hover {
      color: green;
    }
  }
}
</style>
