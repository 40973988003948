<template>
  <div class="sl">
    <ul v-for="(row, rowIndex) in catalogueIndex" :key="rowIndex">
      <li
        v-for="(item, itemIndex) in row"
        :key="itemIndex"
        :class="{ head: itemIndex === 0 }"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import Bus from "../utils/EventBus.js";
export default {
  data() {
    return {
      catalogueIndex: [[]],
      title: "",
    };
  },
  methods: {
    // 对数据处理放入目录中
    dealData(demo) {
      const arr = demo.split("|");
      let catalogueIndex = [];
      let currentLevel = 0;

      for (const item of arr) {
        const [index, title] = item.split("-");

        if (index === "1") {
          catalogueIndex.push([]);
          currentLevel = catalogueIndex.length - 1;
        }

        if (!catalogueIndex[currentLevel]) {
          catalogueIndex[currentLevel] = [];
        }

        catalogueIndex[currentLevel].push(title);
      }

      this.catalogueIndex = catalogueIndex;
    },
    // 对数据进行处理，并且对标头做处理
  },
  created() {
    Bus.$on("essay", (msg) => {
      if (!msg.essay_sonLevelList) {
        this.dealData(`1-${msg.essay_title}`);
      } else {
        this.dealData(msg.essay_sonLevelList);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.sl {
  // background-color: blue;
  width: 250px;
  height: 100%;
  position: fixed;
  margin-left: 25px;
  z-index: 9999;
  border-left: 1px solid #cfd4db;
  // background-color: white;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    margin: 5px 0;
    margin-left: 10%;
    font-size: 20px;
    cursor: pointer;
    // position: absolute;
    // background-color: white;
    // background-color: white;
    .head {
      margin-left: -10px;
      font-weight: bold;
    }
    li {
      font-size: 16px;
      margin: 10px 0;
      margin-left: 15px;
      &:hover {
        color: green;
      }
    }
  }
}
</style>
